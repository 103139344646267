// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';


// Utility classes for title styles.
// These classes are purely presentational. Don't use them if you don't have to.

.mzp-u-title-2xl {
    @include text-title-2xl;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-xl {
    @include text-title-xl;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-lg {
    @include text-title-lg;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-md {
    @include text-title-md;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-sm {
    @include text-title-sm;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-xs {
    @include text-title-xs;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-2xs {
    @include text-title-2xs;
    font-family: get-theme('title-font-family');
}

.mzp-u-title-3xs {
    @include text-title-3xs;
    font-family: get-theme('title-font-family');
}
