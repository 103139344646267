// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.


@-webkit-keyframes mzp-a-fade-in {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes mzp-a-fade-in {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes mzp-a-fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes mzp-a-fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes mzp-a-slide-in-right {
    0% {
        opacity: 0;
        transform: translateX(60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes mzp-a-slide-in-right {
    0% {
        opacity: 0;
        transform: translateX(60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes mzp-a-slide-in-left {
    0% {
        opacity: 0;
        transform: translateX(-60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes mzp-a-slide-in-left {
    0% {
        opacity: 0;
        transform: translateX(-60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

