// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';


html {
    font-size: 100%;
    background: $color-white;
}

body {
    @include text-body-md;
    background: get-theme('background-color');
    color: get-theme('body-text-color');
    font-family: get-theme('body-font-family');
    line-height: type-scale('body-line-height');
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

// A few places use a class to hide elements. We should try to avoid that.
.hidden {
    @include hidden;
}
