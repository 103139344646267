// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';


// We so rarely use tables that it's best not to give them any
// base styling and instead use a modifier class for those few
// times we need to display tabular data.
.mzp-u-data-table {
    border-collapse: collapse;
    border-spacing: 0;

    caption {
        @include bidi(((text-align, left, right),));
        margin-bottom: .25em;
        width: 100%;
    }

    th,
    td {
        @include bidi(((text-align, left, right),));
        border-top: 1px solid rgba(0, 0, 0, .2);
        padding: .5em 10px;
        text-align: left;
    }

    thead th,
    thead td {
        border-top: 0;
        padding-top: 0;
    }
}
