// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

$icon-size: 24px;

.mzp-c-menu-item {
    border-radius: $border-radius-xs;
    margin-bottom: $spacing-lg;

    .mzp-c-menu-item-link {
        display: block;

        &:link,
        &:visited {
            color: $color-black;
            text-decoration: none;

            .mzp-c-menu-item-title {
                border-bottom: 2px solid transparent;
            }
        }

        &:hover,
        &:active,
        &:focus {
            .mzp-c-menu-item-title {
                @include transition(border-bottom-color 100ms ease-in-out);
                border-bottom: 2px solid $color-black;
                color: $color-black;
            }
        }
    }

    .mzp-c-menu-item-head {
        display: block;

        .mzp-c-menu-item-title {
            border-bottom: 2px solid transparent;
        }
    }

    &.mzp-has-icon {
        .mzp-c-menu-item-head,
        .mzp-c-menu-item-link {
            @include bidi(((padding-left, $icon-size + $spacing-lg, padding-right, 0),));
            position: relative;

            .mzp-c-menu-item-icon {
                @include bidi(((left, $spacing-sm, right, auto),));
                height: $icon-size;
                margin: 0;
                position: absolute;
                top: $spacing-sm;
                width: $icon-size;
            }
        }
    }

    .mzp-c-menu-item-title {
        @include text-title-2xs;
        display: inline;
    }

    .mzp-c-menu-item-desc {
        @include text-body-sm;
        margin: $spacing-sm 0 0;
    }

    .mzp-c-menu-item-list {
        @include clearfix;
        @include text-body-sm;
        margin: $spacing-md 0 0;
        position: relative;

        li {
            margin-bottom: $spacing-md;
            word-wrap: break-word;
        }

        a:link,
        a:visited {
            color: $color-black;
            text-decoration: none;
        }

        a:hover,
        a:active,
        a:focus {
            color: $color-black;
            text-decoration: underline;
        }

        @media #{$mq-sm} {
            li {
                @include bidi(((float, left, right),));
                width: calc(50% - #{$spacing-sm});

                &:nth-child(odd) {
                    @include bidi((
                        (clear, left, right),
                        (padding-right, $spacing-sm, padding-left, 0),
                    ));
                }

                &:nth-child(even) {
                    @include bidi(((padding-left, $spacing-sm, padding-right, 0),));
                }
            }
        }
    }

    &.mzp-has-icon .mzp-c-menu-item-list {
        @include bidi(((margin-left, $icon-size + $spacing-lg, margin-right, 0),));
    }

    @media #{$mq-md} {
        &:hover {
            @include transition(box-shadow .1s ease-in-out);
            box-shadow: 0 0 0 4px $color-marketing-gray-20;
        }

        .mzp-c-menu-item-link,
        .mzp-c-menu-item-head {
            padding: $spacing-sm;
        }

        .mzp-c-menu-item-list {
            @include bidi(((margin, $spacing-md 0 0 $spacing-sm, $spacing-md $spacing-sm 0 0),));
        }
    }
}
