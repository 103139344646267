// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';


// We most frequently use lists for semantic structure and don't actually
// want to display them as lists. It's simpler to reset lists globally
// and only display them as lists in text when needed with the .mzp-u-list-styled class.
ul,
ol {
    list-style: none;
}

ul.mzp-u-list-styled {
    @include bidi(((margin-left, $layout-sm, margin-right, 0),));
    list-style: disc;

    li {
        margin-bottom: .25em;
    }

    ul {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        list-style: circle;
        margin-bottom: 0;
    }

    ol {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        list-style: decimal;
        margin-bottom: 0;
    }
}

ol.mzp-u-list-styled {
    @include bidi(((margin-left, $layout-sm, margin-right, 0),));
    list-style: decimal;

    li {
        margin-bottom: .25em;
    }

    ol {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        list-style: lower-alpha;
        margin-bottom: 0;
    }

    ul {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        list-style: disc;
        margin-bottom: 0;
    }
}

dl.mzp-u-list-styled {
    dt {
        font-weight: bold;
        margin-bottom: 0;
    }

    dd {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        margin-bottom: .25em;
    }

    ul, ol {
        @include bidi(((margin-left, $layout-xs, margin-right, 0),));
        margin-bottom: 0;
    }
    ul {
        list-style: circle;
    }
}


/* !important required to trump specificity and print stylesheet overrides */
.mzp-u-list-unstyled {
    list-style: none !important; /* stylelint-disable-line declaration-no-important */
    margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
    margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */

    ul,
    ol {
        list-style: none !important; /* stylelint-disable-line declaration-no-important */
        margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
        margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
}
