// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';

blockquote {
    @include bidi(((border-width, 0 0 0 5px, 0 5px 0 0),));
    @include text-title-sm;
    border-color: $color-marketing-gray-20;
    border-style: solid;
    color: get-theme('title-text-color');
    font-family: get-theme('title-font-family');
    font-weight: bold;
    margin: $spacing-lg auto;
    padding: $spacing-sm $spacing-lg;

    cite {
        @include text-title-xs;
        color: get-theme('body-text-color-secondary');

        &:before {
            content: '— ';
        }
    }

    & > :last-child {
        margin-bottom: 0;
    }
}
