// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.


@font-face {
    font-display: swap;
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}/ZillaSlab-Regular.woff2') format('woff2'),
         url('#{$font-path}/ZillaSlab-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}/ZillaSlab-Bold.woff2') format('woff2'),
         url('#{$font-path}/ZillaSlab-Bold.woff') format('woff');
}
